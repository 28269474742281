import React from 'react';
import { useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getStepIndex } from '@om/redux/selectors/atRetirement/wizardFooter';
import { OmProgressBar } from '@om/component-library-react';

const ProgressBar = () => {
    const { stepIndex } = useSelector(createStructuredSelector({ stepIndex: getStepIndex }));
    const progressBarSteps = [
        'About you',
        'Old Mutual Solutions',
        'Financial Advice',
    ];

    return (
        <OmProgressBar
            index={stepIndex}
            steps={progressBarSteps.join('|')}
            docked
        />
    );
};

export default ProgressBar;

