import React from 'react';
import { OmToast } from '@om/component-library-react';

const AvailableRetirementSavingsToast = () => {
    return (
        <OmToast dark-mode="true">
            <h5 slot="heading" className="no-bottom-margin no-top-margin">
                <span className="extra-light">Source of</span> <br/>retirement savings
            </h5>
            <p slot="content">
                The amount in your retirement annuity, pension fund or provident fund that is maturing.
                Remember you may be entitled to withdraw some of your savings as a cash sum.
            </p>
        </OmToast>
    );
};

export default AvailableRetirementSavingsToast;

