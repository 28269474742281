import React from 'react';
import { useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { OmAccordionItem, OmLabeledValue } from '@om/component-library-react';
import {
    getName,
    getAge,
    getSalary,
    getSavings,
} from '@om/redux/selectors/atRetirement/aboutYou';
import { maskValue } from '@om/redux/functions/AtRetirement/maskValue';

const SummarySection = () => {
    const {
        name,
        age,
        salary,
        savings,
    } = useSelector(
        createStructuredSelector({
            name: getName,
            age: getAge,
            salary: getSalary,
            savings: getSavings,
        })
    );
    const truncatedName = () => {
        return name.value.length < 15 ? name.value : (name.value.substring(0, 15) + '...');
    };

    return (
        <OmAccordionItem
            buttonText="SUMMARY"
            displayMode="card"
            expanded
            hasSeparator
        >
            <span slot="panel-content">
                { name.status === 'valid' ?
                    <h6 className="no-bottom-margin sessioncamhidetext"><strong>{ truncatedName() }</strong></h6>
                    :
                    <h6 className="no-bottom-margin"><strong>Title</strong></h6>
                }
                                
                <br/>
                <OmLabeledValue
                    label="AGE"
                    value={age.status === 'valid' ? age.value: ''}
                />
                <br/>
                <OmLabeledValue 
                    label="CURRENT INCOME"
                    value={salary.status === 'valid' ? maskValue(salary.value) : ''}
                />
                <br/>
                <OmLabeledValue
                    label="RETIREMENT SAVINGS"
                    value={savings.status  === 'valid' ? maskValue(savings.value) : ''}
                />
            </span>
        </OmAccordionItem>
    );
};

export default SummarySection;

