import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { OmSideDrawer } from '@om/component-library-react';
import { setCompositeDrawerClosed } from '@om/redux/actions/atRetirement/solutions';
import { getIsCompositeOpen } from '@om/redux/selectors/atRetirement/solutions';

const CompositeAnnuityInformationDrawer = () => {
    const dispatch = useDispatch();
    const { drawerOpen } = useSelector(createStructuredSelector({ drawerOpen: getIsCompositeOpen }));
    return (
        <OmSideDrawer
            open={drawerOpen}
            onOnSideDrawerClosed={() => dispatch(setCompositeDrawerClosed())}
        >
            <div slot="side-drawer-content" className="annuity-info">
                <h5 className="no-top-margin">
                    <strong>
                        Composite
                    </strong>
                </h5>

                <section>
                    <p>
                        <small>
                            This option combines the features of both worlds,
                            guaranteed and living annuities. It ensures that you
                            receive an income for life and allows you to potentially
                            preserve some of your savings for an inheritance on your
                            death. You can decide how you allocate your retirement
                            savings between the available annuities.
                        </small>
                    </p>
                    <p>
                        <small>
                            The main benefits and drawbacks of this option would be
                            a combination of those mentioned for guaranteed and
                            living annuities separately, depending on how you
                            allocate your retirement savings.
                        </small>
                    </p>
                </section>
            </div>
        </OmSideDrawer>
    );
};

export default CompositeAnnuityInformationDrawer;

