
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { OmSideDrawer } from '@om/component-library-react';
import { setGuaranteedDrawerClosed } from '@om/redux/actions/atRetirement/solutions';
import { getIsGuaranteedOpen } from '@om/redux/selectors/atRetirement/solutions';

const GuaranteedAnnuityInformationDrawer = () => {
    const dispatch = useDispatch();
    const { drawerOpen } = useSelector(createStructuredSelector({ drawerOpen: getIsGuaranteedOpen }));

    return (
        <OmSideDrawer
            open={drawerOpen}
            onOnSideDrawerClosed={() => dispatch(setGuaranteedDrawerClosed())}
        >
            <div slot="side-drawer-content" className="annuity-info">
                <h5 className="no-top-margin">
                    <strong>
                        Guaranteed Annuity
                    </strong>
                </h5>

                <section>
                    <p>
                        <small>
                            This option provides you with an income for as long as
                            you live – there is no longevity risk, which means you
                            won’t outlive your retirement savings.
                        </small>
                    </p>
                </section>

                <section>
                    <h6 className="medium no-bottom-margin">
                        Main benefits of this option
                    </h6>
                    <ul>
                        <li>
                            <p>
                                <small>
                                    You'll receive an income for the rest of your
                                    life, guaranteed!
                                </small>
                            </p>
                        </li>
                        <li>
                            <p>
                                <small>
                                    When purchasing, you can choose from various
                                    options of how your income should change over
                                    time.
                                </small>
                            </p>
                        </li>
                        <li>
                            <p>
                                <small>
                                    If you select a joint life option, you'll have
                                    the option to provide an income for a partner
                                    after you die.
                                </small>
                            </p>
                        </li>
                        <li>
                            <p>
                                <small>
                                    If you select a guaranteed term, an income is
                                    guaranteed to be paid for the term – even
                                    if you die.
                                </small>
                            </p>
                        </li>
                    </ul>
                </section>

                <section>
                    <h6 className="medium no-bottom-margin">
                        Potential drawbacks of this option
                    </h6>
                    <ul>
                        <li>
                            <p>
                                <small>
                                    Although different guarantees will give you
                                    different incomes, you cannot choose how much
                                    income you'll receive.
                                </small>
                            </p>
                        </li>
                        <li>
                            <p>
                                <small>
                                    Apart from possibly specifying a guaranteed term
                                    for income payments (as referred to above), you
                                    are not able to leave your retirement savings as
                                    an inheritance.
                                </small>
                            </p>
                        </li>
                        <li>
                            <p>
                                <small>
                                    Various features selected on your annuity may reduce
                                    the initial income payable.
                                </small>
                            </p>
                        </li>
                    </ul>
                </section>
            </div>
        </OmSideDrawer>
    );
};

export default GuaranteedAnnuityInformationDrawer;

