import React from 'react';
import { useDispatch } from 'react-redux';
import { OmButton, OmOverlayOpener, OmToast } from '@om/component-library-react';
import { toggleSpeakToAnAdviser } from '@om/redux/actions/atRetirement/speakToAnAdviser';

const SpeakToAdviserToast = () => {
    const dispatch = useDispatch();

    return (
        <OmToast closable="false">
            <h6 slot="heading" className="no-top-margin no-bottom-margin">
                <span className="light">Need assistance?</span><br />Speak to us.
            </h6>
            <OmOverlayOpener
                slot="content"
                opening-overlay="speak-to-adviser"
            >
                <OmButton
                    type="secondary"
                    size="small"
                    text="Click Here"
                    onClick={() => dispatch(toggleSpeakToAnAdviser())}
                />
            </OmOverlayOpener>
        </OmToast>
    );
};

export default SpeakToAdviserToast;

