import React, { useEffect } from 'react';
import { useLocation } from '@reach/router'
import { navigate, graphql } from 'gatsby';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import WebComponentsConfig from '../components/WebComponentsConfig';
import ApplicationLayout from '../layouts/ApplicationLayout';
import { OmArcApplicationPage, OmIcon, OmWizardHeader } from '@om/component-library-react'; 

import IntroPage from '../applications/atRetirementCalculator/introPage/IntroPage';
import SolutionsPage from '../applications/atRetirementCalculator/solutionsPage/SolutionsPage';
import AboutYouPage from '../applications/atRetirementCalculator/aboutYouPage/AboutYouPage';
import FinancialAdvicePage from '../applications/atRetirementCalculator/financialAdvicePage/FinancialAdvicePage';

import ProgressBar from '../applications/atRetirementCalculator/ProgressBar';
import WizardFooter from '../applications/atRetirementCalculator/WizardFooter';
import SummarySection from '../applications/atRetirementCalculator/SummarySection';
import IncomeNeededToast from '../applications/atRetirementCalculator/toasts/IncomeNeededToast';
import SpeakToAdviserToast from '../applications/atRetirementCalculator/toasts/SpeakToAdviserToast';
import AvailableRetirementSavingsToast from '../applications/atRetirementCalculator/toasts/AvailableRetirementSavingsToast';
import ChangingIncomeNeedsToast from '../applications/atRetirementCalculator/toasts/ChangingIncomeNeedsToast';
import SpeakToAdviserDrawer from '../applications/atRetirementCalculator/drawers/SpeakToAdviserDrawer';
import BudgetToolDrawer from '../applications/atRetirementCalculator/drawers/BudgetToolDrawer';
import UseOfPersonalInformationDrawer from '../applications/atRetirementCalculator/drawers/UseOfPersonalInformationDrawer';
import GuaranteedAnnuityInformationDrawer from '../applications/atRetirementCalculator/drawers/GuaranteedAnnuityInformationDrawer';
import LivingAnnuityInformationDrawer from '../applications/atRetirementCalculator/drawers/LivingAnnuityInformationDrawer';
import CompositeAnnuityInformationDrawer from '../applications/atRetirementCalculator/drawers/CompositeAnnuityInformationDrawer';
import TwoColumnImageLeft from '../components/TwoColumnImageLeft';

import { setReferrerId } from '@om/redux/actions/atRetirement/atRetirementApplication';
import {
    getAboutYouFormPersonalInformationIsValid,
    getAboutYouFormFinancialIsValid,
    getAboutYouFormRetirementWorriesIsValid,
} from '@om/redux/selectors/atRetirement/aboutYou';
import {
    getSendFindOutMoreStatus,
    getSendSpeakToAdvisorStatus,
} from '@om/redux/selectors/atRetirement/financialAdvice';
import {
    getStepIndex,
    getIsIntroPage,
    getIsSolutionsPage,
    getIsAboutYouPage,
    getIsFinancialAdvicePage
} from '@om/redux/selectors/atRetirement/wizardFooter';
import { setRewardsClicked } from '@om/redux/actions/atRetirement/financialAdvice';
import { getScrollToClass } from '@om/redux/selectors/atRetirement/scrolling';
import { getReferrerId } from '@om/redux/selectors/atRetirement/intro';

// GUID VALIDATION
import { validateGUID } from '@om/validation';

const AtRetirementApplicationPage = ({ data }) => {
    const {
        page_intro,
        four_column_illustration,
        intro_video_url,
        two_column_image_left_intro,
        two_column_image_left_financial_advice
    } = data.contentstackAtRetirementApplication;

    const location = useLocation();
    const dispatch = useDispatch();
    const {
        stepIndex,
        sendFindOutMoreStatus,
        sendSpeakToAdvisorStatus,
        aboutYouFormPersonalInformationIsValid,
        aboutYouFormRetirementWorriesIsValid,
        aboutYouFormFinancialIsValid,
        isIntroPage,
        isSolutionsPage,
        isAboutYouPage,
        isFinancialAdvicePage,
        currentReferrerId,
        scrollToClass
    } = useSelector(
        createStructuredSelector({
            stepIndex: getStepIndex,
            sendFindOutMoreStatus: getSendFindOutMoreStatus,
            sendSpeakToAdvisorStatus: getSendSpeakToAdvisorStatus,
            aboutYouFormPersonalInformationIsValid: getAboutYouFormPersonalInformationIsValid,
            aboutYouFormRetirementWorriesIsValid: getAboutYouFormRetirementWorriesIsValid,
            aboutYouFormFinancialIsValid: getAboutYouFormFinancialIsValid,
            isIntroPage: getIsIntroPage,
            isAboutYouPage: getIsAboutYouPage,
            isSolutionsPage: getIsSolutionsPage,
            isFinancialAdvicePage: getIsFinancialAdvicePage,
            currentReferrerId: getReferrerId,
            scrollToClass: getScrollToClass
        })
    );

    useEffect(() => {
        if (typeof window === 'undefined') {
            return;
        }

        const query = new URLSearchParams(location.search);
        const referrerId = query.get('referrerId')

        if (!referrerId) navigate('/');

        if (!currentReferrerId || referrerId !== currentReferrerId) {
            if (!validateGUID(referrerId)) {
                navigate('/');
            }

            dispatch(setReferrerId(referrerId));
        }
    }, [])

    const setStateAndRedirect = () => {
        dispatch(setRewardsClicked());
        navigate(two_column_image_left_financial_advice[0].button_one_url.href);
    };

    return (
        <WebComponentsConfig>
            <ApplicationLayout>
                <header>
                    <OmWizardHeader>
                        <span slot="sub-title" className="no-top-margin no-bottom-margin hover-span" onClick={() => navigate('/')}>
                            <OmIcon iconName="arrow_back"  />
                            <span className="icon-description-span">Homepage</span>
                        </span>
                    </OmWizardHeader>
                </header>

                <OmArcApplicationPage scrollToClass={scrollToClass}>
                    <div slot="side-drawer-content">
                        <BudgetToolDrawer />
                        <UseOfPersonalInformationDrawer />
                        <GuaranteedAnnuityInformationDrawer />
                        <LivingAnnuityInformationDrawer />
                        <CompositeAnnuityInformationDrawer />
                        <SpeakToAdviserDrawer />
                    </div>

                    <div slot="header-desktop">
                        { !isIntroPage &&
                            <div className="header-wrapper">
                                <h4>At-retirement <strong>Calculator</strong></h4>
                                <p>
                                    Let's work out the income you can potentially get at
                                    retirement.
                                </p>
                                <ProgressBar />
                            </div>
                        }
                    </div>

                    <div slot="header-mobile">
                        <ProgressBar />
                    </div>

                    <div slot="content-no-sidebar-no-styling">
                        { isIntroPage &&
                            <IntroPage
                                stepIndex={stepIndex}
                                csPageIntro={page_intro[0]}
                                csVideoUrl={intro_video_url.href}
                                csTwoColumnImageLeft={two_column_image_left_intro[0]}
                            />
                        }
                        { isSolutionsPage &&
                            <SolutionsPage fourColumnIllustration={four_column_illustration} />
                        }
                    </div>

                    <div slot="content">
                        { isAboutYouPage && 
                            <AboutYouPage />
                        }
                        { isFinancialAdvicePage &&
                            <FinancialAdvicePage />
                        }
                    </div>

                    <div slot="sidebar">
                        { (isAboutYouPage || isFinancialAdvicePage) &&
                            <SummarySection />
                        }
                        { ((aboutYouFormFinancialIsValid && isAboutYouPage) || isFinancialAdvicePage) &&
                            <IncomeNeededToast />
                        }
                        { isAboutYouPage &&
                            <SpeakToAdviserToast />
                        }
                        { aboutYouFormPersonalInformationIsValid && aboutYouFormRetirementWorriesIsValid && isAboutYouPage &&
                            <>
                                <AvailableRetirementSavingsToast />
                                <ChangingIncomeNeedsToast />
                            </>
                        }
                    </div>

                    <div slot="two-column-image-left">
                        { isFinancialAdvicePage && (sendFindOutMoreStatus === 'success' || sendSpeakToAdvisorStatus === 'success') &&
                            <TwoColumnImageLeft
                                buttonClickHandler={() => setStateAndRedirect()}
                                twoColumnImageLeftContent={two_column_image_left_financial_advice[0]}
                            />
                        }
                    </div>

                    <div slot="footer">
                        { !isIntroPage && 
                            <WizardFooter />
                        }
                    </div>

                </OmArcApplicationPage>
            </ApplicationLayout>
        </WebComponentsConfig>
    );
};

export const pageQuery = graphql`
    query ModularAtRetirementApplicationPageQuery($id: String!) {
        contentstackAtRetirementApplication(id: { eq: $id }) {
            page_intro {
                ...PageIntro
            }
            four_column_illustration {
                ...FourColumnIllustration
            }
            intro_video_url {
                ...Link
            }
            two_column_image_left_intro {
                ...TwoColumnImageLeft
            }
            two_column_image_left_financial_advice {
                ...TwoColumnImageLeft
            }
        }
    }
`;

export default AtRetirementApplicationPage;
