import React, {useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { OmArcFinancialAdvicePage, OmButton, OmCheckBox, OmFormInputFieldWrapper } from '@om/component-library-react';

import {
    getFinancialAdviceFormIsValid,
    getContactNumberAdvisor,
    getClientEmail,
    getReasons,
    getShortDescription,
    getSendFindOutMoreStatus,
    getSendSpeakToAdvisorStatus,
} from '@om/redux/selectors/atRetirement/financialAdvice';
import {
    setContactNumberAdvisorValue,
    setShortDescriptionFindOutMoreValue,
    setClientEmailValue,
    sendFindOutMore,
    sendSpeakToAdvisor,
    setReasonsValue,
    setFinancialAdviceRenderTracking,
} from '@om/redux/actions/atRetirement/financialAdvice';
import { getName } from '@om/redux/selectors/atRetirement/aboutYou';
import { API_CALL_STATUS } from '@om/redux/reducers/status';
import { CONTACT_INTERESTS } from '@om/redux/actions/atRetirement/types';
import { DEFAULT_VALIDATION_DELAY } from '../../config';

const FinancialAdvicePage = () => {
    const dispatch = useDispatch();
    const {
        clientEmail,
        shortDescription,
        contactNumberAdvisor,
        reasons,
        financialAdviceFormIsValid,
        sendFindOutMoreStatus,
        sendSpeakToAdvisorStatus,
        name,
    } = useSelector(
        createStructuredSelector({
            clientEmail: getClientEmail,
            shortDescription: getShortDescription,
            contactNumberAdvisor: getContactNumberAdvisor,
            reasons: getReasons,
            financialAdviceFormIsValid: getFinancialAdviceFormIsValid,
            sendFindOutMoreStatus: getSendFindOutMoreStatus,
            sendSpeakToAdvisorStatus: getSendSpeakToAdvisorStatus,
            name: getName,
        })
    );

    const sendButtonText = (sendStatus) => {
        switch (sendStatus) {
        case API_CALL_STATUS.SUCCESS:
        case API_CALL_STATUS.FAILURE:
            return 'SEND AGAIN';
        case API_CALL_STATUS.PENDING:
            return 'SENDING...';
        case API_CALL_STATUS.IDLE:
        default:
            return 'SEND';
        }
    };
    const sendButtonDisabled = (sendStatus, getFormStatus) => {
        return sendStatus === API_CALL_STATUS.PENDING ? true : getFormStatus();
    };
    const findOutMoreButtonDisabled = () => {
        return sendButtonDisabled(sendFindOutMoreStatus, () => clientEmail.status !== 'valid');
    };
    const speakToAdvisorButtonDisabled = () => {
        return sendButtonDisabled(sendSpeakToAdvisorStatus, () => !financialAdviceFormIsValid);
    };

    // dispatch action on render for tracking
    useEffect(() => {
        dispatch(setFinancialAdviceRenderTracking());
    }, []);

    return (
        <OmArcFinancialAdvicePage>
            <div slot="financial-advice-content">
                <h6 className="medium">Want to find out more? We’re here to help.</h6>
                <span className="inline-button">
                    <OmFormInputFieldWrapper
                        isSensitive
                        placeholder="Email address"
                        required
                        size="full"
                        formId="financialAdvice"
                        name="findOutMore"
                        validationDelay={DEFAULT_VALIDATION_DELAY}
                        state={clientEmail.status}
                        value={clientEmail.value}
                        errorMessage={clientEmail.error}
                        onPersist={(event) => dispatch(setClientEmailValue(event.detail))}
                    />
                    <OmButton
                        size="small"
                        type="secondary"
                        text={sendButtonText(sendFindOutMoreStatus)}
                        disabled={findOutMoreButtonDisabled()}
                        onClick={() => dispatch(sendFindOutMore())}
                    />
                </span>

                <div className="desktop-info-content">
                    <p className="find-out-more-text">
                        Email this information to yourself or someone you trust so
                        that you can come back to it later. We’ll include all the
                        information you provided as well as the options available to
                        you.
                    </p>
                    <p className="division-text">Or, if you need assistance</p>
                    <h6 className="medium speak-to-adviser">Speak to us</h6>
                </div>

                <div className="mobile-info-content">
                    <h6 className="medium speak-to-adviser">Speak to us</h6>
                    <p className="more-assistance-text">
                        Alternatively, please reach out to us for more assistance
                    </p>
                </div>

                <p className="medium speak-to-advisor-caption">
                    What are you interested in speaking to us about?
                </p>

                <div className="checkbox-group">
                    <OmCheckBox
                        fieldId="incomeOrProducts"
                        checked={reasons && reasons.value && reasons.value.includes(CONTACT_INTERESTS.INCOME_OR_PRODUCTS)}
                        text="Income or products in retirement"
                        value={CONTACT_INTERESTS.INCOME_OR_PRODUCTS}
                        onOnCheckBoxChanged={() => dispatch(setReasonsValue(CONTACT_INTERESTS.INCOME_OR_PRODUCTS))}
                    />
                    <OmCheckBox
                        field-id="other"
                        checked={reasons && reasons.value && reasons.value.includes(CONTACT_INTERESTS.OTHER)}
                        text="Other retirement matters"
                        value={CONTACT_INTERESTS.OTHER}
                        onOnCheckBoxChanged={() => dispatch(setReasonsValue(CONTACT_INTERESTS.OTHER))}
                    />
                    <OmCheckBox
                        fieldId="somethingElse"
                        checked={reasons && reasons.value && reasons.value.includes(CONTACT_INTERESTS.SOMETHING_ELSE)}
                        text="Something else"
                        value={CONTACT_INTERESTS.SOMETHING_ELSE}
                        onOnCheckBoxChanged={() => dispatch(setReasonsValue(CONTACT_INTERESTS.SOMETHING_ELSE))}
                    />
                </div>

                { (reasons && reasons.value && reasons.value.includes(CONTACT_INTERESTS.SOMETHING_ELSE)) &&
                <div className="something-else">
                    <p className="something-else medium">
                        Something else, okay. Let us know how we can assist.
                    </p>
                    <OmFormInputFieldWrapper
                        placeholder="Hey guys, how can I get some help?"
                        required
                        size="full"
                        formId="financialAdvice"
                        name="shortDescription"
                        validationDelay={DEFAULT_VALIDATION_DELAY}
                        state={shortDescription.status}
                        value={shortDescription.value}
                        errorMessage={shortDescription.error}
                        onPersist={(event) => dispatch(setShortDescriptionFindOutMoreValue(event.detail))}
                    />
                </div>
                }

                <p className={reasons && reasons.value && reasons.value.includes(CONTACT_INTERESTS.SOMETHING_ELSE)
                    ? 'please-provide showing-short-description'
                    : 'please-provide'}
                >
                    Please provide us with your contact details
                </p>
                <div className="speak-to-advisor-form">
                    <OmFormInputFieldWrapper
                        isSensitive
                        placeholder="Contact number"
                        required
                        size="full"
                        formId="financialAdvice"
                        name="contactNumber"
                        validationDelay={DEFAULT_VALIDATION_DELAY}
                        state={contactNumberAdvisor.status}
                        value={contactNumberAdvisor.value}
                        errorMessage={contactNumberAdvisor.error}
                        onPersist={(event) => dispatch(setContactNumberAdvisorValue(event.detail))}
                    />
                    <OmFormInputFieldWrapper
                        isSensitive
                        placeholder="Email address"
                        required
                        size="full"
                        formId="financialAdvice"
                        name="emailAddress"
                        validationDelay={DEFAULT_VALIDATION_DELAY}
                        state={clientEmail.status}
                        value={clientEmail.value}
                        errorMessage={clientEmail.error}
                        onPersist={(event) => dispatch(setClientEmailValue(event.detail))}
                    />
                    <OmButton
                        size="small"
                        type="secondary"
                        text={sendButtonText(sendSpeakToAdvisorStatus)}
                        disabled={speakToAdvisorButtonDisabled()}
                        onClick={() => dispatch(sendSpeakToAdvisor())}
                    />
                </div>

                { (sendSpeakToAdvisorStatus === 'success') &&
                <p className="thanks-text sessioncamhidetext">
                    <strong>
                        Thanks { name.value }, we will contact you as soon as possible.
                    </strong>
                </p>
                }

                <p className="well-share muted">
                    We will reach out to you to answer any questions you might have before taking the next step. This consultation will be free of charge.
                </p>
                <p className="well-share muted">
                    By completing your email address above or checking any of the boxes
                    above, you agree to being contacted by Old Mutual to receive information
                    and/or offers on Old Mutual products and consent that your personal
                    information be used for these purposes and as described in the&nbsp;
                    <a href="https://www.oldmutual.co.za/privacy-policy/">Privacy Policy.</a>
                </p>
                <p className="well-share muted">
                    You also agree that your consent wil override any marketing blocks
                    that you may have registered before. You may withdraw your consent
                    at any time.
                </p>
                <span className="rewards-anchor success-anchor"></span>
            </div>
        </OmArcFinancialAdvicePage>
    );
};

export default FinancialAdvicePage;

