import { createSelector } from "reselect";
import { getAtRetirement } from "./index";

export const getScrolling = createSelector(
    getAtRetirement,
    atRetirement => atRetirement.scrolling
);

export const getScrollToClass = createSelector(
    getScrolling,
    scrolling => scrolling.scrollToClass,
)