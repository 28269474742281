import React, {useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { DEFAULT_VALIDATION_DELAY } from '../../config';
import {
    OmFormInputFieldWrapper,
    OmArcAboutYou,
    OmButtonSelector,
    OmButtonSelectorContainer,
    OmRadioButtonGroup
} from '@om/component-library-react';

import {
    RETIREMENT_FOR,
    RETIREMENT_WORRIES,
    GENDER,
} from '@om/redux/actions/atRetirement/types';
import {
    toggleBudgetTool,
    toggleUseOfPersonalInformation,
    setNameValue,
    setAgeValue,
    setGenderValue,
    setRetirementForValue,
    setPartnerAgeValue,
    setRetirementWorriesValue,
    setSalaryValue,
    setSavingsValue,
    setBudgetValue,
    setAboutYouRenderTracking,
} from '@om/redux/actions/atRetirement/aboutYou';
import { setRewardsSolutionsValue } from '@om/redux/actions/atRetirement/rewardsSolutionsTable';
import {
    getAboutYouFormPersonalInformationIsValid,
    getAboutYouFormRetirementWorriesIsValid,
    getName,
    getAge,
    getGender,
    getRetirementFor,
    getRetirementWorries,
    getPartnerAge,

    getSalary,
    getSavings,
    getBudget,
} from '@om/redux/selectors/atRetirement/aboutYou';

const AboutYouPage = () => {
    const dispatch = useDispatch();
    const {
        aboutYouFormPersonalInformationIsValid,
        aboutYouFormRetirementWorries,
        name,
        age,
        gender,
        retirementFor,
        retirementWorries,
        partnerAge,
        salary,
        savings,
        budget
    } = useSelector(
        createStructuredSelector({
            aboutYouFormPersonalInformationIsValid: getAboutYouFormPersonalInformationIsValid,
            aboutYouFormRetirementWorries: getAboutYouFormRetirementWorriesIsValid,
            name: getName,
            age: getAge,
            gender: getGender,
            retirementFor: getRetirementFor,
            retirementWorries: getRetirementWorries,
            partnerAge: getPartnerAge,
            salary: getSalary,
            savings: getSavings,
            budget: getBudget,
        })
    );

    const setSavingsAndConsiderationValue = (savings) => {
        dispatch(setSavingsValue(savings));
        dispatch(setRewardsSolutionsValue(savings));
    };

    // dispatch action on render for tracking
    useEffect(() => {
        dispatch(setAboutYouRenderTracking());
    }, []);

    return (
        <OmArcAboutYou>
            <p slot="heading" className="has-progress-bar">
                First, please tell us about yourself
            </p>
            <p slot="subheading">
                <small>
                    <a onClick={() => dispatch(toggleUseOfPersonalInformation())}>
                        How we'll use your information
                    </a>
                </small>
            </p>
            <div slot="form">
                <section className="about-1-section">
                    <p>
                        <small>
                            Hi, my name is &nbsp;
                            <OmFormInputFieldWrapper
                                isSensitive
                                required
                                size="medium"
                                form-id="aboutYouForm"
                                name="First name"
                                align="left"
                                placeholderAsLabel="false"
                                validationDelay={DEFAULT_VALIDATION_DELAY}
                                state={name.status}
                                value={name.value}
                                errorMessage={name.error}
                                errorMessageDisplayType="pop-up"
                                errorMessagePopupAlignment="center"
                                onPersist={(event) => dispatch(setNameValue(event.detail))}
                            />&nbsp;
                            <span className="mobile-line-break"><br /></span>
                            I am &nbsp;
                            <OmFormInputFieldWrapper
                                required
                                size="small"
                                form-id="aboutYouForm"
                                name="Age"
                                align="left"
                                placeholderAsLabel="false"
                                maxlength="3"
                                validationDelay={DEFAULT_VALIDATION_DELAY}
                                state={age.status}
                                value={age.value}
                                errorMessage={age.error}
                                errorMessageDisplayType="pop-up"
                                errorMessagePopupAlignment="center"
                                errorMessagePopupAlignmentMobile="left"
                                onPersist={(event) => dispatch(setAgeValue(event.detail))}
                            />
                            &nbsp; years old.
                        </small>
                    </p>
                    <span className="about-you-top" />

                    <div>
                        <p className="inline-group">
                            <small>
                                I am &nbsp;
                            </small>
                        </p>
                        <OmButtonSelectorContainer
                            onOnSelection={(event) => dispatch(setGenderValue(event.detail))}
                        >
                            <OmButtonSelector
                                slot="option-1"
                                value={GENDER.MALE}
                                text={GENDER.MALE}
                                selected={gender.value === GENDER.MALE}
                                selectorGroup="gender"
                            />
                            <OmButtonSelector
                                slot="option-2"
                                value={GENDER.FEMALE}
                                text={GENDER.FEMALE}
                                selected={gender.value === GENDER.FEMALE}
                                selectorGroup="gender"
                            />
                        </OmButtonSelectorContainer>
                    </div>

                    <p className="inline-group">
                        <small>
                            I want to set up a retirement income for &nbsp;
                        </small>
                    </p>
                    <span className="mobile-line-break"><br /><br /></span>
                    <OmRadioButtonGroup
                        vertical="false"
                        groupName="retirement-for"
                        value={retirementFor.value}
                        onOnSelection={(event) => dispatch(setRetirementForValue(event.detail)) }
                    >
                        <ul>
                            <li value={RETIREMENT_FOR.MYSELF}>
                                Myself
                            </li>
                            <li value={RETIREMENT_FOR.MYSELF_AND_PARTNER}>
                                Me & my partner
                            </li>
                        </ul>
                    </OmRadioButtonGroup>

                    { (retirementFor.value === RETIREMENT_FOR.MYSELF_AND_PARTNER) &&
                        <p>
                            <small>
                                My partner is &nbsp;
                                <OmFormInputFieldWrapper
                                    placeholder=""
                                    required
                                    size="small"
                                    form-id="aboutYouForm"
                                    name="Partner Age"
                                    align="left"
                                    maxlength="3"
                                    validationDelay={DEFAULT_VALIDATION_DELAY}
                                    state={partnerAge.status}
                                    value={partnerAge.value}
                                    errorMessage={partnerAge.error}
                                    errorMessageDisplayType="pop-up"
                                    errorMessagePopupAlignment="center"
                                    onPersist={(event) => dispatch(setPartnerAgeValue(event.detail))}
                                />
                                &nbsp; years old.
                            </small>
                        </p>
                    }

                </section>
                <span className="about-2-anchor" />
                { aboutYouFormPersonalInformationIsValid &&
                    <section className="about-2-section">
                        <p>
                            <small>
                                Now that I am going into retirement, the thing that
                                worries me most is:
                            </small>
                        </p>
                        <OmRadioButtonGroup
                            indent
                            groupName="retirement-worries"
                            value={retirementWorries.value}
                            onOnSelection={(event) => dispatch(setRetirementWorriesValue(event.detail)) }
                        >
                            <ul>
                                <li value={RETIREMENT_WORRIES.INCOME_FOR_REST_OF_LIFE}>
                                    Not having the certainty of receiving an income for
                                    the rest of my life
                                </li>
                                <li value={RETIREMENT_WORRIES.INHERITANCE_FOR_LOVED_ONES}>
                                    Not being able to leave an inheritance for my loved
                                    ones
                                </li>
                                <li value={RETIREMENT_WORRIES.BOTH}>
                                    Both
                                </li>
                            </ul>
                        </OmRadioButtonGroup>
                    </section>
                }

                <span className="about-3-anchor" ></span>
                { (aboutYouFormRetirementWorries && aboutYouFormPersonalInformationIsValid) &&
                    <section className="about-3-section">
                        <p>
                            <small>
                                I earn <strong>R</strong>&nbsp;
                                <OmFormInputFieldWrapper
                                    placeholder=""
                                    required
                                    size="medium"
                                    mobile-size="small"
                                    form-id="aboutYouForm"
                                    name="Salary"
                                    mask="thousands-spaced"
                                    validationDelay={DEFAULT_VALIDATION_DELAY}
                                    state={salary.status}
                                    value={salary.value}
                                    errorMessage={salary.error}
                                    errorMessageDisplayType="pop-up"
                                    errorMessagePopupAlignment="center"
                                    errorMessagePopupAlignmentMobile="left"
                                    onPersist={(event) => dispatch(setSalaryValue(event.detail))}
                                />
                                &nbsp; each month before tax.
                            </small>
                        </p>
                        <p>
                            <small>
                                In total, I have <strong>R</strong>&nbsp;
                                <OmFormInputFieldWrapper
                                    placeholder=""
                                    required
                                    size="medium"
                                    mobile-size="small"
                                    form-id="aboutYouForm"
                                    name="Savings"
                                    mask="thousands-spaced"
                                    validationDelay={DEFAULT_VALIDATION_DELAY}
                                    state={savings.status}
                                    value={savings.value}
                                    errorMessage={savings.error}
                                    errorMessageDisplayType="pop-up"
                                    errorMessagePopupAlignment="center"
                                    onPersist={(event) => setSavingsAndConsiderationValue(event.detail)}
                                />
                                &nbsp; in retirement savings.
                                <span className="light">
                                    &nbsp;Not sure? A ballpark figure is fine.
                                </span>
                            </small>
                        </p>
                        <span className="mobile-line-break"><br /></span>
                        <p>
                            <small>
                                From these retirement savings, I think I'll need
                                <span className="mobile-line-break"><br /></span>
                                <strong> R</strong>&nbsp;
                                <OmFormInputFieldWrapper
                                    placeholder=""
                                    required
                                    size="medium"
                                    mobile-size="small"
                                    form-id="aboutYouForm"
                                    name="Budget"
                                    mask="thousands-spaced"
                                    validationDelay={DEFAULT_VALIDATION_DELAY}
                                    state={budget.status}
                                    value={budget.value}
                                    errorMessage={budget.error}
                                    errorMessageDisplayType="pop-up"
                                    errorMessagePopupAlignment="center"
                                    errorMessagePopupAlignmentMobile="left"
                                    onPersist={(event) => dispatch(setBudgetValue(event.detail))}
                                />
                                &nbsp; per month (after tax) once I've retired.&nbsp;
                                <a className="light" onClick={() => dispatch(toggleBudgetTool())}>
                                    Not sure? Click here
                                </a>
                            </small>
                        </p>
                    </section>
                }
            </div>
        </OmArcAboutYou>
    );
};

export default AboutYouPage;

