
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { OmSideDrawer } from '@om/component-library-react';
import { setLivingDrawerClosed } from '@om/redux/actions/atRetirement/solutions';
import { getIsLivingOpen } from '@om/redux/selectors/atRetirement/solutions';

const LivingAnnuityInformationDrawer = () => {
    const dispatch = useDispatch();
    const { drawerOpen } = useSelector(createStructuredSelector({ drawerOpen: getIsLivingOpen }));

    return (
        <OmSideDrawer
            open={drawerOpen}
            onOnSideDrawerClosed={() => dispatch(setLivingDrawerClosed())}
        >
            <div slot="side-drawer-content" className="annuity-info">
                <h5 className="no-top-margin">
                    <strong>
                        Living Annuity
                    </strong>
                </h5>

                <section>
                    <p>
                        <small>
                            This option allows you to choose the amount of income
                            you'll receive from what you've invested. You are
                            allowed to change your income amount every year. This
                            must be done carefully – choosing a high drawdown rate
                            could mean that you run out of money. The income you
                            choose will also be subject to regulated limits.
                        </small>
                    </p>

                    <p>
                        <small>
                            You could possibly preserve some of your retirement
                            savings for your dependents. However, you run the risk
                            of running out of money if you live longer than expected
                            or if the market performs worse than expected.
                        </small>
                    </p>
                </section>

                <section>
                    <h6 className="medium no-bottom-margin">
                        Main benefits of this option
                    </h6>
                    <ul>
                        <li>
                            <p>
                                <small>
                                    Every year you can choose how much income to
                                    draw, within the regulated limits.
                                </small>
                            </p>
                        </li>
                        <li>
                            <p>
                                <small>
                                    You can possibly preserve some of your
                                    retirement savings for your dependents as an
                                    inheritance.
                                </small>
                            </p>
                        </li>
                    </ul>

                    <h6 className="medium no-bottom-margin">
                        Potential drawbacks of this option
                    </h6>

                    <ul>
                        <li>
                            <p>
                                <small>
                                    You could run out of money – this would mean
                                    that you cannot draw the income you need nor
                                    leave an inheritance.
                                </small>
                            </p>
                        </li>
                        <li>
                            <p>
                                <small>
                                    If the investment markets perform worse than
                                    expected, you may need to draw less income than
                                    what you would like.
                                </small>
                            </p>
                        </li>
                        <li>
                            <p>
                                <small>
                                    You may need to accept a lower starting income
                                    to increase the likelihood of you having a
                                    sustainable income.
                                </small>
                            </p>
                        </li>
                    </ul>
                </section>
            </div>
        </OmSideDrawer>
    );
};

export default LivingAnnuityInformationDrawer;

