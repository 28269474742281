import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { OmButton, OmFormInputFieldWrapper, OmSideDrawer } from '@om/component-library-react';
import { DEFAULT_VALIDATION_DELAY } from '../../config';

import { toggleBudgetTool } from '@om/redux/actions/atRetirement/aboutYou';
import { getBudgetDrawerIsOpen } from '@om/redux/selectors/atRetirement/aboutYou';
import {
    setDayToDaySpendingValue,
    setDebtsValue,
    setFixedMonthlyExpensesValue,
    setInsuranceValue,
    setBudgetValue,
} from '@om/redux/actions/atRetirement/budgetTool';
import {
    getBudgetToolFormIsValid,
    getDayToDaySpending,
    getDebts,
    getFixedMonthlyExpenses,
    getInsurance,
    getBudgetValue,
} from '@om/redux/selectors/atRetirement/budgetTool';
import { maskValue } from '@om/redux/functions/AtRetirement/maskValue';

const BudgetToolDrawer = () => {
    const dispatch = useDispatch();
    const {
        budgetToolDrawerIsOpen,
        budgetToolFormIsValid,
        fixedMonthlyExpenses,
        dayToDaySpending,
        insurance,
        debts,
        budget,
    } = useSelector(
        createStructuredSelector({
            budgetToolDrawerIsOpen: getBudgetDrawerIsOpen,
            budgetToolFormIsValid: getBudgetToolFormIsValid,
            fixedMonthlyExpenses: getFixedMonthlyExpenses,
            dayToDaySpending: getDayToDaySpending,
            insurance: getInsurance,
            debts: getDebts,
            budget: getBudgetValue,
        })
    );

    return (
        <OmSideDrawer
            open={budgetToolDrawerIsOpen}
            onOnSideDrawerClosed={() => dispatch(toggleBudgetTool())}
        >
            <div slot="side-drawer-content">
                { !budget ?
                    <div>
                        <h5 className="no-top-margin"><strong>Budget</strong></h5>
                        <h6 className="no-top-margin">
                            Let's work out how much you may need. Today, roughly how
                            much do you spend each month on:
                        </h6>
                        <OmFormInputFieldWrapper
                            placeholder="Fixed monthly expenses"
                            required
                            size="full"
                            formId="budgetToolForm"
                            name="fixedMonthlyExpenses"
                            hintText="(e.g. household bills, subscriptions, etc)"
                            mask="thousands-spaced"
                            validationDelay={DEFAULT_VALIDATION_DELAY}
                            state={fixedMonthlyExpenses.status}
                            value={fixedMonthlyExpenses.value}
                            errorMessage={fixedMonthlyExpenses.error}
                            onPersist={(event) => dispatch(setFixedMonthlyExpensesValue(event.detail))}
                        />
                        <OmFormInputFieldWrapper
                            placeholder="Day-to-day spending"
                            required
                            size="full"
                            formId="budgetToolForm"
                            name="dayToDaySpending"
                            hintText="(e.g. groceries, travel, supporting your family, etc)"
                            mask="thousands-spaced"
                            validationDelay={DEFAULT_VALIDATION_DELAY}
                            state={dayToDaySpending.status}
                            value={dayToDaySpending.value}
                            errorMessage={dayToDaySpending.error}
                            onPersist={(event) => dispatch(setDayToDaySpendingValue(event.detail))}
                        />
                        <OmFormInputFieldWrapper
                            placeholder="Insurance and savings"
                            required
                            size="full"
                            formId="budgetToolForm"
                            name="insurance"
                            mask="thousands-spaced"
                            hintText="(e.g. life insurance, medical cover, savings, etc)"
                            validationDelay={DEFAULT_VALIDATION_DELAY}
                            state={insurance.status}
                            value={insurance.value}
                            errorMessage={insurance.error}
                            onPersist={(event) => dispatch(setInsuranceValue(event.detail))}
                        />
                        <OmFormInputFieldWrapper
                            placeholder="Paying off debts"
                            required
                            size="full"
                            formId="budgetToolForm"
                            name="debts"
                            mask="thousands-spaced"
                            hintText="(e.g. loans, credit cards, store accounts, etc)"
                            validationDelay={DEFAULT_VALIDATION_DELAY}
                            state={debts.status}
                            value={debts.value}
                            errorMessage={debts.error}
                            onPersist={(event) => dispatch(setDebtsValue(event.detail))}
                        />
                        <br />
                        <OmButton
                            type="primary"
                            size="small"
                            text="calculate"
                            disabled={!budgetToolFormIsValid}
                            onClick={() => dispatch(setBudgetValue())}
                        />
                    </div>
                    :
                    <div>
                        <h5 className="no-top-margin"><strong>Budget</strong></h5>
                        <h6 className="no-top-margin">
                            Based on your budget, it looks as though you may need an
                            income of R{ maskValue(budget) } per month after
                            retirement.
                        </h6>
                    </div>
                }
            </div>
        </OmSideDrawer>
    );
};

export default BudgetToolDrawer;

