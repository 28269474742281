import React from 'react';
import { graphql } from 'gatsby';
import { OmFourColumnIllustration } from '@om/component-library-react';

const FourColumnIllustration = ({
    fourColumnLayout,
    slot
}) => {

    if(!fourColumnLayout[0]) {
        return '';
    }

    const {
        has_boundary,
        use_heading_as_h1,
        section_heading,
        section_caption,
        img_url_first,
        icon_name_first,
        sub_title_first,
        paragraph_first,
        side_drawer_content_first,
        button_text_first,
        img_url_second,
        icon_name_second,
        sub_title_second,
        paragraph_second,
        side_drawer_content_second,
        button_text_second,
        img_url_third,
        icon_name_third,
        sub_title_third,
        paragraph_third,
        side_drawer_content_third,
        button_text_third,
        img_url_fourth ,
        icon_name_fourth,
        sub_title_fourth,
        paragraph_fourth,
        side_drawer_content_fourth,
        button_text_fourth,
        button_text,
        open_tab,
        button_url,
        button_action_type,
        call_me_back,
        redirect_url_first,
        redirect_url_first_android,
        redirect_url_first_apple,
        redirect_url_second,
        redirect_url_second_android,
        redirect_url_second_apple,
        redirect_url_third,
        redirect_url_fourth,
        redirect_url_third_android,
        redirect_url_third_apple,
        redirect_url_fourth_android,
        redirect_url_fourth_apple,
    } = fourColumnLayout[0];

    return (
        <OmFourColumnIllustration
            slot={slot}
            hasBoundary={has_boundary}
            imageUrlFirst={img_url_first && img_url_first.url ? img_url_first.url : ''}
            redirectUrlFirst={redirect_url_first}
            redirectUrlFirstAndroid={redirect_url_first_android}
            redirectUrlFirstApple={redirect_url_first_apple}
            iconNameFirst={icon_name_first}
            subTitleFirst={sub_title_first}
            paragraphFirst={paragraph_first}
            imageUrlSecond={img_url_second && img_url_second.url ? img_url_second.url : ''}
            redirectUrlSecond={redirect_url_second}
            redirectUrlSecondAndroid={redirect_url_second_android}
            redirectUrlSecondApple={redirect_url_second_apple}
            iconNameSecond={icon_name_second}
            subTitleSecond={sub_title_second}
            paragraphSecond={paragraph_second}
            imageUrlThird={img_url_third && img_url_third.url ? img_url_third.url : ''}
            redirectUrlThird={redirect_url_third}
            redirectUrlThirdAndroid={redirect_url_third_android}
            redirectUrlThirdApple={redirect_url_third_apple}
            iconNameThird={icon_name_third}
            subTitleThird={sub_title_third}
            paragraphThird={paragraph_third}
            imageUrlFourth={img_url_fourth && img_url_fourth.url ? img_url_fourth.url : ''}
            redirectUrlFourth={redirect_url_fourth}
            redirectUrlFourthAndroid={redirect_url_fourth_android}
            redirectUrlFourthApple={redirect_url_fourth_apple}
            iconNameFourth={icon_name_fourth}
            subTitleFourth={sub_title_fourth}
            paragraphFourth={paragraph_fourth}
            buttonTextFirst={button_text_first}
            buttonTextSecond={button_text_second}
            buttonTextThird={button_text_third}
            buttonTextFourth={button_text_fourth}
            buttonTexts={button_text}
            buttonUrl={button_url && button_url.href}
            buttonActionType={button_action_type}
            buttonCallMeBackObject={call_me_back}
            openTab={open_tab}
        >
            {
                use_heading_as_h1
                    ? <h1 slot="section-heading" dangerouslySetInnerHTML={{__html: section_heading}} ></h1>
                    : <span slot="section-heading" dangerouslySetInnerHTML={{__html: section_heading}} ></span>
            }
            {
                section_caption
                    ? <span slot="section-caption" dangerouslySetInnerHTML={{__html: section_caption}} />
                    : ''
            }
            {
                side_drawer_content_first
                    ? <span slot="side-drawer-content-first" dangerouslySetInnerHTML={{__html: side_drawer_content_first}}/>
                    : ''
            }
            {
                side_drawer_content_second
                    ? <span slot="side-drawer-content-second" dangerouslySetInnerHTML={{__html: side_drawer_content_second}}/>
                    : ''
            }
            {
                side_drawer_content_third
                    ? <span slot="side-drawer-content-third" dangerouslySetInnerHTML={{__html: side_drawer_content_third}}/>
                    : ''
            }
            {
                side_drawer_content_fourth
                    ? <span slot="side-drawer-content-fourth" dangerouslySetInnerHTML={{__html: side_drawer_content_fourth}}/>
                    : ''
            }
        </OmFourColumnIllustration>
    );
};

export const pageQuery = graphql`
    fragment FourColumnIllustration on Contentstack_four_column_illustration {
        uid
        has_boundary
        use_heading_as_h1
        section_heading
        section_caption
        img_url_first {
            ...File
        }
        icon_name_first
        sub_title_first
        paragraph_first
        side_drawer_content_first
        button_text_first
        img_url_second {
            ...File
        }
        icon_name_second
        sub_title_second
        paragraph_second
        side_drawer_content_second
        button_text_second
        img_url_third {
            ...File
        }
        icon_name_third
        sub_title_third
        paragraph_third
        side_drawer_content_third
        button_text_third
        img_url_fourth {
            ...File
        }
        icon_name_fourth
        sub_title_fourth
        paragraph_fourth
        side_drawer_content_fourth
        button_text_fourth
        button_text
        open_tab
        button_url {
            ...Link
        }
        button_action_type
        call_me_back {
            ...CallMeBack
        }

        redirect_url_first
        redirect_url_first_android
        redirect_url_first_apple
        redirect_url_second
        redirect_url_second_android
        redirect_url_second_apple
        redirect_url_third
        redirect_url_fourth
        redirect_url_third_android
        redirect_url_third_apple
        redirect_url_fourth_android
        redirect_url_fourth_apple
    }
`;

export default FourColumnIllustration;
