import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { OmSideDrawer } from '@om/component-library-react';

import { toggleUseOfPersonalInformation } from '@om/redux/actions/atRetirement/aboutYou';
import { getUseOfPersonalInformationDrawerIsOpen } from '@om/redux/selectors/atRetirement/aboutYou';

const UseOfPersonalInformationDrawer = () => {
    const dispatch = useDispatch();
    const { drawerOpen } = useSelector(
        createStructuredSelector({
            drawerOpen: getUseOfPersonalInformationDrawerIsOpen
        })
    );

    return (
        <OmSideDrawer
            open={drawerOpen}
            onOnSideDrawerClosed={() => dispatch(toggleUseOfPersonalInformation())}
        >
            <div slot="side-drawer-content">
                <h5 className="no-top-margin">
                    <strong>Use of your personal information</strong>
                </h5>
                <p className="no-top-margin">
                    <small>
                        The personal information that you enter will not be stored
                        in any manner or used for marketing purposes unless you
                        request that a financial adviser contact you. You are
                        welcome to use a fictional name when simply using the
                        retirement calculator to see what level of income you might
                        receive in retirement.
                    </small>
                </p>
            </div>
        </OmSideDrawer>
    );
};

export default UseOfPersonalInformationDrawer;

