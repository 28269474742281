import React from 'react';
import { useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { OmTableContainer } from '@om/component-library-react';
import {
    getRewardsTier1,
    getRewardsTier2,
    getRewardsTier3,
    getRewardsTier4,
    getRewardsTier5,
} from '@om/redux/selectors/atRetirement/rewardsSolutionsTable';
import { maskValue } from '@om/redux/functions/AtRetirement/maskValue';

const ProductSolutionsTable = ({ slotName }) => {
    const {
        rewardsTier1,
        rewardsTier2,
        rewardsTier3,
        rewardsTier4,
        rewardsTier5,
    } = useSelector(
        createStructuredSelector({
            rewardsTier1: getRewardsTier1,
            rewardsTier2: getRewardsTier2,
            rewardsTier3: getRewardsTier3,
            rewardsTier4: getRewardsTier4,
            rewardsTier5: getRewardsTier5,
        })
    );

    return (
        <div slot={slotName}>
            <OmTableContainer
                tableColumnsDesktop="6"
                tableHeaderRowsDesktop="1"
                tableBodyRowsDesktop="1"
                tablesMobile="1"
                tableHeaderRowsMobile="2"
                tableBodyColumnsMobile="2"
                tableBodyRowsMobile="5"
                tableFooterRowsMobile="0"
                tableStyle="generic"
                hasBoundary="false"
                expandedMobileOnLoad="true"
            >
                <span slot="heading">
                    Old Mutual rewards you could earn
                </span>
                <span slot="caption">
                    Your tier status is linked to the amount of points you
                    accumulate when completing qualifying earning activities
                    like completing financial assessments, using online
                    calculators or by having certain products
                    with Old Mutual.<strong> 10 points = R1</strong>
                    <br />
                    <br />
                    <em>
                        Old Mutual Rewards (Pty) Ltd. is a company in the Old
                        Mutual Group. The Rules of the Old Mutual Rewards
                        Programme apply.
                    </em>
                </span>

                <div slot="desktop-table-header-row-1-cell-1">
                    <h6><strong>Rewards tiers</strong></h6>
                </div>
                <div slot="desktop-table-header-row-1-cell-2">
                    <h6 className="medium"><small>Tier1</small></h6>
                </div>
                <div slot="desktop-table-header-row-1-cell-3">
                    <h6 className="medium"><small>Tier 2</small></h6>
                </div>
                <div slot="desktop-table-header-row-1-cell-4">
                    <h6 className="medium"><small>Tier 3</small></h6>
                </div>
                <div slot="desktop-table-header-row-1-cell-5">
                    <h6><small className="medium">Tier 4</small></h6>
                </div>
                <div slot="desktop-table-header-row-1-cell-6">
                    <h6><small className="medium">Tier 5</small></h6>
                </div>

                <div slot="desktop-table-body-row-1-cell-1">
                    <h6 className="medium">Rewards points</h6>
                </div>
                <div slot="desktop-table-body-row-1-cell-2">
                    <p>
                        <small>{ maskValue(rewardsTier1) }</small>
                    </p>
                </div>
                <div slot="desktop-table-body-row-1-cell-3">
                    <p>
                        <small>{ maskValue(rewardsTier2) }</small>
                    </p>
                </div>
                <div slot="desktop-table-body-row-1-cell-4">
                    <p>
                        <small>{ maskValue(rewardsTier3) }</small>
                    </p>
                </div>
                <div slot="desktop-table-body-row-1-cell-5">
                    <p>
                        <small>{ maskValue(rewardsTier4) }</small>
                    </p>
                </div>
                <div slot="desktop-table-body-row-1-cell-6">
                    <p>
                        <small>{ maskValue(rewardsTier5) }</small>
                    </p>
                </div>

                <div slot="mobile-table-1-header-row-1-cell-1">
                    <h6 className="medium">Rewards points</h6>
                </div>

                <div slot="mobile-table-1-header-row-2-cell-1">
                    <br />
                    <h6 className="medium">Rewards tiers</h6>
                    <br />
                </div>
                <div slot="mobile-table-1-body-row-1-cell-1">
                    <h6 className="medium"><small>Tier 1</small></h6>
                </div>
                <div slot="mobile-table-1-body-row-1-cell-2">
                    <p>
                        <small>{ maskValue(rewardsTier1) }</small>
                    </p>
                </div>

                <div slot="mobile-table-1-body-row-2-cell-1">
                    <h6 className="medium"><small>Tier 2</small></h6>
                </div>
                <div slot="mobile-table-1-body-row-2-cell-2">
                    <p>
                        <small>{ maskValue(rewardsTier2) }</small>
                    </p>
                </div>

                <div slot="mobile-table-1-body-row-3-cell-1">
                    <h6 className="medium"><small>Tier 3</small></h6>
                </div>
                <div slot="mobile-table-1-body-row-3-cell-2">
                    <p>
                        <small>{ maskValue(rewardsTier3) }</small>
                    </p>
                </div>

                <div slot="mobile-table-1-body-row-4-cell-1">
                    <h6 className="medium"><small>Tier 4</small></h6>
                </div>
                <div slot="mobile-table-1-body-row-4-cell-2">
                    <p>
                        <small>{ maskValue(rewardsTier4) }</small>
                    </p>
                </div>

                <div slot="mobile-table-1-body-row-5-cell-1">
                    <h6 className="medium"><small>Tier 5</small></h6>
                </div>
                <div slot="mobile-table-1-body-row-5-cell-2">
                    <p>
                        <small>{ maskValue(rewardsTier5) }</small>
                    </p>
                </div>
            </OmTableContainer>
        </div>
    );
};

export default ProductSolutionsTable;

