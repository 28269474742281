import React from 'react';
import { useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getBudget } from '@om/redux/selectors/atRetirement/aboutYou';
import { OmInformationDisplay, OmToast } from '@om/component-library-react';
import { maskValue } from '@om/redux/functions/AtRetirement/maskValue';

const IncomeNeededToast = () => {
    const { budget } = useSelector(createStructuredSelector({ budget: getBudget }));

    return (
        <OmToast dark-mode="false" closable="false">
            <OmInformationDisplay
                slot="content"
                image-url="https://eu-images.contentstack.com/v3/assets/blt0554f48052bb4620/blte2bb9a493c741aaf/5dbacb29df78486c826df785/Beach_Chair_Illustration.png"
                grid="false"
                separator="false"
            >
                <div slot="content">
                    <p className="no-bottom-margin no-top-margin">
                        Monthly income I'll need when I'm retired (after tax).
                    </p>
                    <h4 className="no-bottom-margin no-top-margin">
                        R { maskValue(budget.value) }
                    </h4>
                </div>
            </OmInformationDisplay>
        </OmToast>
    );
};

export default IncomeNeededToast;

