import { createSelector } from "reselect";
import { getAtRetirement } from "./index";

export const getRewardsSolutionsTable = createSelector(
    getAtRetirement,
    atRetirement => atRetirement.rewardsSolutionsTable
);

export const getRewardsTier1 = createSelector(
    getRewardsSolutionsTable,
    rewardsSolutionsTable => rewardsSolutionsTable.tier1,
);

export const getRewardsTier2 = createSelector(
    getRewardsSolutionsTable,
    rewardsSolutionsTable => rewardsSolutionsTable.tier2,
);

export const getRewardsTier3 = createSelector(
    getRewardsSolutionsTable,
    rewardsSolutionsTable => rewardsSolutionsTable.tier3,
);

export const getRewardsTier4 = createSelector(
    getRewardsSolutionsTable,
    rewardsSolutionsTable => rewardsSolutionsTable.tier4,
);

export const getRewardsTier5 = createSelector(
    getRewardsSolutionsTable,
    rewardsSolutionsTable => rewardsSolutionsTable.tier5,
);