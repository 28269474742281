import React, {useEffect} from 'react';
import { useDispatch } from 'react-redux';
import { OmArcIntroPage } from '@om/component-library-react';
import TwoColumnImageLeft from '../../../components/TwoColumnImageLeft';
import PageIntro from '../../../components/PageIntro';

import { setStepIndexValue } from '@om/redux/actions/atRetirement/wizardFooter';
import { setScrollToTop } from'@om/redux/actions/atRetirement/scrolling';
import { setIntroRenderTracking } from '@om/redux/actions/atRetirement/intro';
import { ARC_ALL_PAGES } from '@om/redux/middleware/rulesEngine/status';

const IntroPage = ({ stepIndex, csVideoUrl, csPageIntro, csTwoColumnImageLeft }) => {
    const dispatch = useDispatch();
    const incrementStepIndex = () => {
        dispatch(setStepIndexValue(stepIndex + 1));
        dispatch(setScrollToTop(ARC_ALL_PAGES.PAGE_TOP));
    };

    // dispatch action on render for tracking
    useEffect(() => {
        dispatch(setIntroRenderTracking());
    }, []);

    return (
        <OmArcIntroPage video-src-url={csVideoUrl}>
            <PageIntro
                slotName="page-intro"
                pageIntroContent={csPageIntro}
            />
            <TwoColumnImageLeft
                slot="two-column-image-left"
                twoColumnImageLeftContent={csTwoColumnImageLeft}
                buttonClickHandler={() => incrementStepIndex()}
            />
        </OmArcIntroPage>
    );
};

export default IntroPage;

