import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { OmTableContainer, OmIcon } from '@om/component-library-react';
import OmButton from '../../../components/forms/OmButton';
import { SOLUTIONS } from '@om/redux/actions/atRetirement/types';
import {
    setGuaranteedDrawerOpen,
    setLivingDrawerOpen,
    setCompositeDrawerOpen,
    setGauranteedChosen,
    setLivingChosen,
    setCompositeChosen,
} from '@om/redux/actions/atRetirement/solutions';
import {
    getGuaranteedAnnuityLowerRange,
    getGuaranteedAnnuityUpperRange,
    getLivingAnnuityLowerRange,
    getLivingAnnuityUpperRange,
} from '@om/redux/selectors/atRetirement/productSolutionsTable';
import { getSolutionsProductChosen } from '@om/redux/selectors/atRetirement/solutions';
import { getRetirementWorries } from '@om/redux/selectors/atRetirement/aboutYou';
import { applyThousandSeperator } from '../../../util';

const ProductSolutionsTable = ({ slotName }) => {
    const dispatch = useDispatch();
    const {
        retirementWorries,
        guaranteedAnnuityLowerRange,
        guaranteedAnnuityUpperRange,
        livingAnnuityLowerRange,
        livingAnnuityUpperRange,
        selectedProduct,
    } = useSelector(
        createStructuredSelector({
            retirementWorries: getRetirementWorries,
            guaranteedAnnuityLowerRange: getGuaranteedAnnuityLowerRange,
            guaranteedAnnuityUpperRange: getGuaranteedAnnuityUpperRange,
            livingAnnuityLowerRange: getLivingAnnuityLowerRange,
            livingAnnuityUpperRange: getLivingAnnuityUpperRange,
            selectedProduct: getSolutionsProductChosen,
        })
    );

    const setSelectButtonText = (button) => {
        return selectedProduct === button ? 'selected' : 'select';
    };
    const configureGARange = () => {
        return determineValidRange( guaranteedAnnuityLowerRange, guaranteedAnnuityUpperRange );
    };
    const configureLARange = () => {
        return determineValidRange(
            livingAnnuityLowerRange,
            livingAnnuityUpperRange
        );
    };
    const determineValidRange = (lower, upper) => {
        if (lower >= upper) {
            return `Around R${applyThousandSeperator(lower)} pm`;
        } else {
            return `R${applyThousandSeperator(lower)} pm to R${applyThousandSeperator(upper)} pm`;
        }
    };

    return (
        <div slot={slotName}>
            <OmTableContainer
                tableColumnsDesktop="4"
                tableHeaderRowsDesktop="1"
                tableBodyRowsDesktop="4"
                tablesMobile="3"
                tableHeaderRowsMobile="2"
                tableBodyColumnsMobile="2"
                tableBodyRowsMobile="4"
                tableFooterRowsMobile="1"
                tableStyle="comparison"
                hasBoundary="false"
            >
                <span slot="heading">
                    Solutions available to you
                </span>
                <span slot="caption">
                    You said what worries you most about retirement is
                    { retirementWorries.value === 'INCOME_FOR_REST_OF_LIFE' &&
                        <span>
                            not having the certainty of receiving an income for the rest
                            of your life. A Guaranteed Annuity is designed to address this
                            concern.
                        </span>
                    }
                    { retirementWorries.value === 'INHERITANCE_FOR_LOVED_ONES' &&
                        <span>
                            not being able to leave an inheritance for your loved ones. A
                            Living Annuity is designed to address this concern.
                        </span>
                    }
                    { retirementWorries.value === 'BOTH' &&
                        <span>
                            both not having the certainty of receiving an income for the rest
                            of your life and not being able to leave an inheritance for your
                            loved ones. A composite of Guaranteed and Living Annuity is
                            designed to address these concerns.
                        </span>
                    }
                    Here are 3 possible solutions for you.
                </span>

                <div slot="desktop-table-header-row-1-cell-1">
                    <h6 className="medium">Benefits</h6>
                </div>
                <div slot="desktop-table-header-row-1-cell-2">
                    <h6 className="medium">Guaranteed Annuity</h6>
                    <h6>
                        <small>
                            You receive a guaranteed income for the rest of your
                            life.
                            <br /><br />
                        </small>
                    </h6>
                    <OmButton
                        type="primary"
                        size="small"
                        text={ setSelectButtonText(SOLUTIONS.GUARANTEED_ANNUITY) }
                        url="#"
                        onClick={(event) => dispatch(setGauranteedChosen(event.detail))}
                    />
                    <OmButton
                        type="text"
                        size="small"
                        text="More Detail"
                        url="#"
                        onClick={(event) => dispatch(setGuaranteedDrawerOpen(event.detail))}
                    />
                </div>
                <div slot="desktop-table-header-row-1-cell-3">
                    <h6 className="medium">Living Annuity</h6>
                    <h6>
                        <small>
                            Choose your income, with the possibility of leaving an
                            inheritance but risk running out of money.
                            <br />
                        </small>
                    </h6>
                    <OmButton
                        type="primary"
                        size="small"
                        text={ setSelectButtonText(SOLUTIONS.LIVING_ANNUITY) }
                        url="#"
                        onClick={(event) => dispatch(setLivingChosen(event.detail))}
                    />
                    <OmButton
                        type="text"
                        size="small"
                        text="More Detail"
                        url="#"
                        onClick={(event) => dispatch(setLivingDrawerOpen(event.detail))}
                    />
                </div>
                <div slot="desktop-table-header-row-1-cell-4">
                    <h6 className="medium">Composite</h6>
                    <h6>
                        <small>
                            A combination of a guaranteed income for life and the
                            option to choose how much your pension payments will be.
                        </small>
                    </h6>
                    <OmButton
                        type="primary"
                        size="small"
                        text={ setSelectButtonText(SOLUTIONS.COMPOSITE_ANNUITY) }
                        url="#"
                        onClick={(event) => dispatch(setCompositeChosen(event.detail))}
                    />
                    <OmButton
                        type="text"
                        size="small"
                        text="More Detail"
                        url="#"
                        onClick={(event) => dispatch(setCompositeDrawerOpen(event.detail))}
                    />
                </div>
                <div slot="desktop-table-body-row-1-cell-1">
                    <h6 className="medium">
                        <small>
                            Example of starting income*
                        </small>
                    </h6>
                </div>
                <div slot="desktop-table-body-row-1-cell-2">
                    <h6>
                        <b>{ configureGARange() }</b>
                    </h6>
                </div>
                <div slot="desktop-table-body-row-1-cell-3">
                    <h6>
                        <b>{ configureLARange() }</b>
                    </h6>
                </div>
                <div slot="desktop-table-body-row-1-cell-4">
                    <h6>
                        <small>
                            Income will depend on the percentage allocation between
                            a Guaranteed and a Living Annuity.
                        </small>
                    </h6>
                </div>

                <div slot="desktop-table-body-row-2-cell-1">
                    <h6>
                        <small>
                            Income guaranteed for life
                        </small>
                    </h6>
                </div>
                <div slot="desktop-table-body-row-2-cell-2">
                    <OmIcon iconName="check"/>
                </div>
                <div slot="desktop-table-body-row-2-cell-3">
                    <OmIcon iconName="close"/>
                </div>
                <div slot="desktop-table-body-row-2-cell-4">
                    <OmIcon iconName="check"/>
                    <h6>
                        <small>
                            This depends on the percentage allocation between a
                            Guaranteed and a Living Annuity.
                        </small>
                    </h6>
                </div>

                <div slot="desktop-table-body-row-3-cell-1">
                    <h6>
                        <small>
                            Possibility of providing an inheritance
                        </small>
                    </h6>
                </div>
                <div slot="desktop-table-body-row-3-cell-2">
                    <OmIcon iconName="close"/>
                    <h6>
                        <small>
                            Although you can choose to guarantee an income for a
                            specified term – even if you die.
                        </small>
                    </h6>
                </div>
                <div slot="desktop-table-body-row-3-cell-3">
                    <OmIcon iconName="check"/>
                </div>
                <div slot="desktop-table-body-row-3-cell-4">
                    <OmIcon iconName="check"/>
                    <h6>
                        <small>
                            This depends on the percentage allocation between a
                            Guaranteed and a Living Annuity.
                        </small>
                    </h6>
                </div>

                <div slot="desktop-table-body-row-4-cell-1">
                    <h6>
                        <small>
                            I can choose my income
                        </small>
                    </h6>
                </div>
                <div slot="desktop-table-body-row-4-cell-2">
                    <OmIcon iconName="close"/>
                    <h6>
                        <small>
                            Although different guarantees will give you different
                            incomes
                        </small>
                    </h6>
                </div>
                <div slot="desktop-table-body-row-4-cell-3">
                    <OmIcon iconName="check"/>
                    <br />
                    <small>
                        Annually
                    </small>
                </div>
                <div slot="desktop-table-body-row-4-cell-4">
                    <OmIcon iconName="check"/>
                    <h6>
                        <small>
                            This depends on the percentage allocation between a
                            Guaranteed and a Living Annuity.
                        </small>
                    </h6>
                </div>

                <div slot="mobile-table-1-header-row-1-cell-1">
                    <h6 className="medium">Guaranteed Annuity</h6>
                    <h6>
                        <small>
                            You receive a guaranteed income for the rest of your
                            life.
                            <br /><br />
                        </small>
                    </h6>
                    <OmButton
                        type="primary"
                        size="small"
                        text={ setSelectButtonText(SOLUTIONS.GUARANTEED_ANNUITY) }
                        url="#"
                        onClick={(event) => dispatch(setGauranteedChosen(event.detail))}
                    />
                </div>

                <div slot="mobile-table-1-header-row-2-cell-1">
                    <h6 className="medium">
                        <strong>
                            Benefits
                        </strong>
                    </h6>
                </div>

                <div slot="mobile-table-1-body-row-1-cell-1">
                    <h6>
                        <small>
                            <strong>
                                Example of starting income*
                            </strong>
                        </small>
                    </h6>
                </div>
                <div slot="mobile-table-1-body-row-1-cell-2">
                    <h6>
                        <b>{ configureGARange() }</b>
                    </h6>
                </div>

                <div slot="mobile-table-1-body-row-2-cell-1">
                    <h6>
                        <small>
                            Income guaranteed for life
                        </small>
                    </h6>
                </div>
                <div slot="mobile-table-1-body-row-2-cell-2">
                    <OmIcon iconName="check"/>
                </div>

                <div slot="mobile-table-1-body-row-3-cell-1">
                    <h6>
                        <small>
                            Possibility of providing an inheritance
                        </small>
                    </h6>
                </div>
                <div slot="mobile-table-1-body-row-3-cell-2">
                    <OmIcon iconName="close"/>
                    <h6>
                        <small>
                            Although you can choose to guarantee an income – even if
                            you die
                        </small>
                    </h6>
                </div>

                <div slot="mobile-table-1-body-row-4-cell-1">
                    <h6>
                        <small>
                            I can choose my income
                        </small>
                    </h6>
                </div>
                <div slot="mobile-table-1-body-row-4-cell-2">
                    <OmIcon iconName="close"/>
                    <h6>
                        <small>
                            Although different guarantees will give you different
                            incomes
                        </small>
                    </h6>
                </div>

                <div slot="mobile-table-1-footer-row-1-cell-1">
                    <OmButton
                        type="text"
                        size="small"
                        text="More Detail"
                        url="#"
                        onClick={(event) => dispatch(setGuaranteedDrawerOpen(event.detail))}
                    />
                </div>

                <div slot="mobile-table-2-header-row-1-cell-1">
                    <h6 className="medium">Living Annuity</h6>
                    <h6>
                        <small>
                            Choose your income, with the possibility of leaving an
                            inheritance but risk running out of money.
                        </small>
                    </h6>
                    <OmButton
                        type="primary"
                        size="small"
                        text={ setSelectButtonText(SOLUTIONS.LIVING_ANNUITY) }
                        url="#"
                        onClick={(event) => dispatch(setLivingChosen(event.detail))}
                    />
                </div>

                <div slot="mobile-table-2-header-row-2-cell-1">
                    <h6 className="medium">
                        <strong>
                            Benefits
                        </strong>
                    </h6>
                </div>

                <div slot="mobile-table-2-body-row-1-cell-1">
                    <h6>
                        <small>
                            <strong>
                                Example of starting income*
                            </strong>
                        </small>
                    </h6>
                </div>
                <div slot="mobile-table-2-body-row-1-cell-2">
                    <h6>
                        <b>{ configureLARange() }</b>
                    </h6>
                </div>

                <div slot="mobile-table-2-body-row-2-cell-1">
                    <h6>
                        <small>
                            Income guaranteed for life
                        </small>
                    </h6>
                </div>
                <div slot="mobile-table-2-body-row-2-cell-2">
                    <OmIcon iconName="close"/>
                </div>

                <div slot="mobile-table-2-body-row-3-cell-1">
                    <h6>
                        <small>
                            Possibility of providing an inheritance
                        </small>
                    </h6>
                </div>
                <div slot="mobile-table-2-body-row-3-cell-2">
                    <OmIcon iconName="check"/>
                </div>

                <div slot="mobile-table-2-body-row-4-cell-1">
                    <h6>
                        <small>
                            I can choose my income
                        </small>
                    </h6>
                </div>
                <div slot="mobile-table-2-body-row-4-cell-2">
                    <OmIcon iconName="check"/>
                    <h6>
                        <small>
                            Annually
                        </small>
                    </h6>
                </div>

                <div slot="mobile-table-2-footer-row-1-cell-1">
                    <OmButton
                        type="text"
                        size="small"
                        text="More Detail"
                        url="#"
                        onClick={(event) => dispatch(setLivingDrawerOpen(event.detail))}
                    />
                </div>

                <div slot="mobile-table-3-header-row-1-cell-1">
                    <h6 className="medium">Composite</h6>
                    <h6>
                        <small>
                            A combination of a guaranteed income for life and the
                            option to choose how much your pension payments will be.
                        </small>
                    </h6>
                    <OmButton
                        type="primary"
                        size="small"
                        text={ setSelectButtonText(SOLUTIONS.COMPOSITE_ANNUITY) }
                        url="#"
                        onClick={(event) => dispatch(setCompositeChosen(event.detail))}
                    />
                </div>

                <div slot="mobile-table-3-header-row-2-cell-1">
                    <h6 className="medium">
                        <strong>
                            Benefits
                        </strong>
                    </h6>
                </div>

                <div slot="mobile-table-3-body-row-1-cell-1">
                    <h6>
                        <small>
                            <strong>
                                Example of starting income*
                            </strong>
                        </small>
                    </h6>
                </div>
                <div slot="mobile-table-3-body-row-1-cell-2">
                    <h6>
                        <small>
                            Income will depend on the percentage allocation between
                            guaranteed and living annuities.
                        </small>
                    </h6>
                </div>

                <div slot="mobile-table-3-body-row-2-cell-1">
                    <h6>
                        <small>
                            Income guaranteed for life
                        </small>
                    </h6>
                </div>
                <div slot="mobile-table-3-body-row-2-cell-2">
                    <OmIcon iconName="check"/>
                    <h6>
                        <small>
                            This depends on the percentage allocation between a Guaranteed
                            and a Living Annuity.
                        </small>
                    </h6>
                </div>

                <div slot="mobile-table-3-body-row-3-cell-1">
                    <h6>
                        <small>
                            Possibility of providing an inheritance
                        </small>
                    </h6>
                </div>
                <div slot="mobile-table-3-body-row-3-cell-2">
                    <OmIcon iconName="check"/>
                    <h6>
                        <small>
                            This depends on the percentage allocation between a Guaranteed
                            and a Living Annuity.
                        </small>
                    </h6>
                </div>

                <div slot="mobile-table-3-body-row-4-cell-1">
                    <h6>
                        <small>
                            I can choose my income
                        </small>
                    </h6>
                </div>
                <div slot="mobile-table-3-body-row-4-cell-2">
                    <OmIcon iconName="check"/>
                    <h6>
                        <small>
                            This depends on the percentage allocation between a Guaranteed
                            and a Living Annuity.
                        </small>
                    </h6>
                </div>

                <div slot="mobile-table-3-footer-row-1-cell-1">
                    <OmButton
                        type="text"
                        size="small"
                        text="More Detail"
                        url="#"
                        onClick={(event) => dispatch(setCompositeDrawerOpen(event.detail))}
                    />
                </div>
                <div slot="disclaimer">
                    <small>
                        Since the calculator does not take all of your other circumstances
                        and financial information into account, the values indicated in
                        this calculator are neither intended, nor does it constitute,
                        financial advice. For this reason it is recommended that you speak
                        to a suitably accredited professional to assist you to make the best
                        decision that is suitable for your unique set of circumstances. Where
                        you have, at any stage during the use of this calculator, opted for
                        the assistance of a professional, you agree for your personal
                        information to be shared for purposes of obtaining that support.
                        <br />
                        * Please note that these income ranges displayed are only indicative.
                        The income available to you will depend on the actual annuity(ies)
                        purchased, the detail of the live(s) covered, your investment, the
                        specific features chosen by you and the actual date of your purchase.
                        The range of incomes for the Guaranteed Annuity is based on Level and
                        Inflation-linked annuities, using annuity rates available in December
                        2019. The range of incomes for the Living Annuity is based on the
                        minimum and maximum drawdown rates allowed as per regulation. It is
                        important to note, though, that a high drawdown rate could deplete
                        your invested capital sooner than expected.
                    </small>
                </div>
            </OmTableContainer>
        </div>
    );
};

export default ProductSolutionsTable;

