import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { OmButton, OmWizardFooter } from '@om/component-library-react';

import { setStepIndexValue } from '@om/redux/actions/atRetirement/wizardFooter';
import { setScrollToTop } from '@om/redux/actions/atRetirement/scrolling';
import {
    getAboutYouContinueButtonDisabled,
    getSolutionsContinueButtonDisabled,
    getContinueButtonVisible,
    getStepIndex,
} from '@om/redux/selectors/atRetirement/wizardFooter';
import { ARC_ALL_PAGES } from '@om/redux/middleware/rulesEngine/status';

const WizardFooter = () => {
    const { 
        aboutYouContinueButtonDisabled,
        solutionsContinueButtonDisabled,
        showContinueButton,
        stepIndex,
    } = useSelector(
        createStructuredSelector({
            aboutYouContinueButtonDisabled: getAboutYouContinueButtonDisabled,
            solutionsContinueButtonDisabled: getSolutionsContinueButtonDisabled,
            showContinueButton: getContinueButtonVisible,
            stepIndex: getStepIndex,

        })
    );
    const dispatch = useDispatch();

    const setStepIndexValueAndScrollTop = (index) => {
        dispatch(setStepIndexValue(index));
        dispatch(setScrollToTop(ARC_ALL_PAGES.PAGE_TOP));
    };
    const continueButtonIsDisabled = () => {
        if (stepIndex === 0) {
            return aboutYouContinueButtonDisabled;
        } else if (stepIndex === 1) {
            return solutionsContinueButtonDisabled;
        }
    };

    return (
        <OmWizardFooter index={stepIndex} totalCount="3">
            <OmButton
                slot="left"
                type="text"
                size="small"
                text="Previous"
                onClick={() => dispatch(setStepIndexValue(stepIndex - 1))}
            />
            <div slot="right">
                { showContinueButton && stepIndex !== 2 && 
                    <OmButton
                        type="primary"
                        size="small"
                        text="Continue"
                        disabled={continueButtonIsDisabled()}
                        onClick={() => setStepIndexValueAndScrollTop(stepIndex + 1)}
                    />
                }
            </div>
        </OmWizardFooter>
    );
};

export default WizardFooter;

