import React from 'react';
import { graphql } from 'gatsby';
import { OmPageIntro } from '@om/component-library-react';
import { ThemeContext } from '../context/ThemeContext';

const PageIntro = ({
    pageIntroContent,
    slotName
}) => {
    if (!pageIntroContent) {
        return '';
    }

    const theme = React.useContext(ThemeContext);

    const {
        uid,
        text_align_center,
        use_heading_as_h1,
        heading,
        sub_title,
        caption,
        has_boundary
    } = pageIntroContent;

    return (
        <section slot={slotName}>
            <div className={`${theme} om-section`} style={{ textAlign: "center" }}>
                {has_boundary ? <div class="boundary-line-wrapper"><hr style={{ marginBottom: "0px" }} /></div> : null}
            </div>
            <OmPageIntro
                id={uid}
                textAlignCenter={text_align_center}
                hasBoundary={has_boundary}
            >
                {
                    heading ?
                        (use_heading_as_h1 ?
                            <div style={text_align_center ? { textAlign: 'center' } : { textAlign: '' }} slot="heading" dangerouslySetInnerHTML={{ __html: `<h1>${heading}</h1>` }} /> :
                            <span slot="heading" dangerouslySetInnerHTML={{ __html: heading }} />) :
                        ''
                }
                {
                    sub_title ?
                        <span slot="sub-title" dangerouslySetInnerHTML={{ __html: sub_title }} /> :
                        ''
                }
                {
                    caption ?
                        <div style={text_align_center ? { textAlign: 'center' } : { textAlign: '' }} slot="caption" dangerouslySetInnerHTML={{ __html: caption }} /> :
                        ''
                }
                {/* <span v-show="caption_sanitized" slot="caption" v-html="caption_sanitized" /> */}
            </OmPageIntro>

        </section>
    );
};

export const pageQuery = graphql`
fragment PageIntro on Contentstack_page_intro  {
    uid
    text_align_center
    top_line
    heading
    sub_title
    caption
    use_heading_as_h1
    has_boundary
  }
`;

export default PageIntro;