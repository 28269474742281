import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { OmButton, OmSideDrawer, OmFormInputFieldWrapper, OmCheckBox } from '@om/component-library-react';
import { DEFAULT_VALIDATION_DELAY } from '../../config';

import { CONTACT_INTERESTS } from '@om/redux/actions/atRetirement/types';
import { API_CALL_STATUS } from '@om/redux/reducers/status';
import {
    toggleSpeakToAnAdviser,
    setReasonsValue,
    setMoreHelpValue,
    setContactNumberValue,
    setEmailAddressValue,
    sendSpeakToAnAdviser,
} from '@om/redux/actions/atRetirement/speakToAnAdviser';
import {
    getSpeakToAnAdviserDrawerIsOpen,
    getSpeakToAnAdviserFormIsValid,
    getReasons,
    getMoreHelp,
    getEmailAddress,
    getContactNumber,
    getSendSpeakToAnAdviserStatus,
} from '@om/redux/selectors/atRetirement/speakToAnAdviser';

const SpeakToAdviserDrawer = () => {
    const dispatch = useDispatch();
    const {
        drawerOpen,
        speakToAnAdviserFormIsValid,
        reasons,
        moreHelp,
        emailAddress,
        contactNumber,
        sendSpeakToAnAdviserStatus,
    } = useSelector(
        createStructuredSelector({
            drawerOpen: getSpeakToAnAdviserDrawerIsOpen,
            speakToAnAdviserFormIsValid: getSpeakToAnAdviserFormIsValid,
            reasons: getReasons,
            moreHelp: getMoreHelp,
            emailAddress: getEmailAddress,
            contactNumber: getContactNumber,
            sendSpeakToAnAdviserStatus: getSendSpeakToAnAdviserStatus,
        })
    );

    const speakToAnAdviserButtonDisabled = () => {
        return sendButtonDisabled(sendSpeakToAnAdviserStatus, () => !speakToAnAdviserFormIsValid);
    };
    const sendButtonText = (sendStatus) => {
        switch (sendStatus) {
        case API_CALL_STATUS.SUCCESS:
        case API_CALL_STATUS.FAILURE:
            return 'SEND AGAIN';
        case API_CALL_STATUS.PENDING:
            return 'SENDING...';
        case API_CALL_STATUS.IDLE:
        default:
            return 'SEND';
        }
    };
    const sendButtonDisabled = (sendStatus, getFormStatus) => {
        return sendStatus === API_CALL_STATUS.PENDING ? true : getFormStatus();
    };

    return (
        <OmSideDrawer
            open={drawerOpen}
            onOnSideDrawerClosed={() => dispatch(toggleSpeakToAnAdviser())}
        >
            <div slot="side-drawer-content" className="speak-to-an-adviser">
                <h5 className="no-top-margin">
                    <strong>Speak to us</strong>
                </h5>
                <h6 className="no-top-margin">
                    What are you interested in speaking to us about?
                </h6>
                <div className="call-me-back-checkbox-container">
                    <p>
                        <OmCheckBox
                            field-id="IncomeOrProducts"
                            checked={reasons && reasons.value && reasons.value.includes(CONTACT_INTERESTS.INCOME_OR_PRODUCTS)}
                            text="Income or products in retirement"
                            value={CONTACT_INTERESTS.INCOME_OR_PRODUCTS}
                            onOnCheckBoxChanged={() => dispatch(setReasonsValue(CONTACT_INTERESTS.INCOME_OR_PRODUCTS))}
                        />
                    </p>
                    <p>
                        <OmCheckBox
                            field-id="Other"
                            checked={reasons &&reasons.value &&reasons.value.includes(CONTACT_INTERESTS.OTHER)}
                            text="Other retirement matters"
                            value={CONTACT_INTERESTS.OTHER}
                            onOnCheckBoxChanged={() => dispatch(setReasonsValue(CONTACT_INTERESTS.OTHER))}
                        />
                    </p>
                    <p>
                        <OmCheckBox
                            field-id="SomethingElse"
                            checked={reasons && reasons.value && reasons.value.includes(CONTACT_INTERESTS.SOMETHING_ELSE)}
                            text="Something else"
                            value={CONTACT_INTERESTS.SOMETHING_ELSE}
                            onOnCheckBoxChanged={() => dispatch(setReasonsValue(CONTACT_INTERESTS.SOMETHING_ELSE))}
                        />
                    </p>
                </div>

                { reasons && reasons.value && reasons.value.includes(CONTACT_INTERESTS.SOMETHING_ELSE) &&
                <div className="something-else">
                    <p>
                        <small>
                            <strong>
                                Something else, okay. Let us know how we can
                                assist
                            </strong>
                        </small>
                    </p>
                    <OmFormInputFieldWrapper
                        placeholder="How do I get some help, please?"
                        required
                        size="full"
                        formId="speakToAnAdviserForm"
                        name="MoreHelp"
                        validationDelay={DEFAULT_VALIDATION_DELAY}
                        state={moreHelp.status}
                        value={moreHelp.value}
                        errorMessage={moreHelp.error}
                        onPersist={(event) => dispatch(setMoreHelpValue(event.detail))}
                    />
                </div>
                }

                <div className="contact-details">
                    <h6 className="no-bottom-margin"><strong>Great news!</strong></h6>
                    <p>
                        <small>
                            This consultation will be free of charge. Please provide
                            us with your contact details
                        </small>
                    </p>
                    <OmFormInputFieldWrapper
                        isSensitive
                        placeholder="Contact number"
                        required
                        size="full"
                        formId="speakToAnAdviserForm"
                        name="ContactNumber"
                        align="left"
                        validationDelay={DEFAULT_VALIDATION_DELAY}
                        state={contactNumber.status}
                        value={contactNumber.value}
                        errorMessage={contactNumber.error}
                        onPersist={(event) => dispatch(setContactNumberValue(event.detail))}
                    />
                    <br />
                    <OmFormInputFieldWrapper
                        isSensitive
                        placeholder="Email address"
                        required
                        size="full"
                        formId="speakToAnAdviserForm"
                        name="EmailAddress"
                        align="left"
                        validationDelay={DEFAULT_VALIDATION_DELAY}
                        state={emailAddress.status}
                        value={emailAddress.value}
                        error-message={emailAddress.error}
                        onPersist={(event) => dispatch(setEmailAddressValue(event.detail))}
                    />
                </div>
                <div className="form-submit-details">
                    <OmButton
                        type="secondary"
                        size="small"
                        text={sendButtonText(sendSpeakToAnAdviserStatus)}
                        disabled={speakToAnAdviserButtonDisabled()}
                        onClick={() => dispatch(sendSpeakToAnAdviser())}
                    />

                    { sendSpeakToAnAdviserStatus === 'success' &&
                    <p className="form-submit-details-success">
                        <strong>
                            Thanks, someone will contact you as soon as
                            possible.
                        </strong>
                    </p>
                    }

                    <p>
                        <small>
                            We will reach out to you to answer any questions you might have before taking the next step. This consultation will be free of charge.
                        </small>
                    </p>
                </div>
            </div>
        </OmSideDrawer>
    );
};

export default SpeakToAdviserDrawer;

