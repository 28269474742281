import React from 'react';
import { OmToast } from '@om/component-library-react';

const ChangingIncomeNeedsToast = () => {
    return (
        <OmToast dark-mode="true">
            <h5 slot="heading" className="no-bottom-margin no-top-margin">
                <span className="extra-light">Your changing</span><br />income needs
            </h5>
            <p slot="content">
                After retiring, people usually need roughly 75% of their
                pre-retirement income
            </p>
        </OmToast>
    );
};

export default ChangingIncomeNeedsToast;

