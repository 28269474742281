import React, {useEffect} from 'react';
import { graphql } from 'gatsby';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { handleOmButtonClick } from './forms/OmButton';
import { OmTwoColumnImageLeft } from '@om/component-library-react';
import { getIsFormOpenAndPrepopulated} from '@om/redux/selectors/publicWeb/callMeBack';
import {
    toggleComplaintForm,
    toggleArbitrationForm
} from '@om/redux/actions/customerComplaint/complaintArbitration';

const TwoColumnImageLeft = ({
    twoColumnImageLeftContent,
    slot,
    buttonClickHandler
}) => {
    const dispatch = useDispatch();

    let cmbData;

    if(twoColumnImageLeftContent){
        cmbData = twoColumnImageLeftContent.call_me_back_primary[0] ?
            twoColumnImageLeftContent.call_me_back_primary :
            twoColumnImageLeftContent?.call_me_back_secondary;
    }

    const {
        isPrepopulatedCmb
    } = useSelector(
        createStructuredSelector({
            isPrepopulatedCmb: getIsFormOpenAndPrepopulated,

        })
    );

    useEffect(() => {
        if(isPrepopulatedCmb && cmbData)
            handleOmButtonClick(cmbData, dispatch, isPrepopulatedCmb, 'TwoColumnImageLeft');
    }, [
        isPrepopulatedCmb,
        cmbData
    ]);

    if (!twoColumnImageLeftContent) {
        return '';
    }

    const {
        uid,
        has_boundary,
        image_url,
        image_title,
        image_alt,
        video,
        video_url,
        button_one_action_type,
        button_one_text,
        button_one_url,
        button_one_open_tab,
        button_two_action_type,
        button_two_text,
        button_two_url,
        button_two_open_tab,
        button_three_url,
        button_three_image_url,
        button_three_open_tab,
        button_four_url,
        button_four_open_tab,
        call_me_back_primary,
        call_me_back_secondary,
        button_four_image_url,
        use_heading_as_h1,
        text_heading,
        text_content,
        text_sub_title,
        podcast_series_id,
        podcast_episode_id,
        roa_quote_tool_primary,
        roa_quote_tool_secondary
    } = twoColumnImageLeftContent;


    


    return (
        <OmTwoColumnImageLeft
            slot={slot}
            id={uid}
            hasBoundary={has_boundary}
            buttonOneActionType={button_one_action_type}
            buttonOneText={button_one_text}
            buttonOneUrl={button_one_url ? button_one_url.href : ''}
            buttonOneOpenTab={button_one_open_tab}
            buttonTwoActionType={button_two_action_type}
            buttonTwoText={button_two_text}
            buttonTwoUrl={button_two_url ? button_two_url.href : ''}
            buttonTwoOpenTab={button_two_open_tab}
            callMeBackPrimary={call_me_back_primary ? JSON.stringify(call_me_back_primary[0]) : ''}
            callMeBackSecondary={call_me_back_secondary ? JSON.stringify(call_me_back_secondary[0]) : ''}
            roaQuoteToolPrimary={roa_quote_tool_primary ? JSON.stringify(roa_quote_tool_primary[0]) : ''}
            roaQuoteToolSecondary={roa_quote_tool_secondary ? JSON.stringify(roa_quote_tool_secondary[0]) : ''}
            buttonThreeUrl={button_three_url ? button_three_url.href : ''}
            buttonThreeImageUrl={button_three_image_url ? button_three_image_url.url : ''}
            buttonThreeOpenTab={button_three_open_tab}
            buttonFourUrl={button_four_url ? button_four_url.href : ''}
            buttonFourImageUrl={button_four_image_url ? button_four_image_url.url : ''}
            buttonFourOpenTab={button_four_open_tab}
            podcastSeriesId={podcast_series_id}
            podcastEpisodeId={podcast_episode_id}
            onButtonClicked={(e) => {
                if(e.detail === 'Complaint') {
                    dispatch(toggleComplaintForm());
                } else if (e.detail === 'Arbitration') {
                    dispatch(toggleArbitrationForm());
                } else if (buttonClickHandler) {
                    buttonClickHandler();
                }
            }}
        >
            {
                text_heading ?
                    (use_heading_as_h1 ?
                        <h1 slot="text-heading" dangerouslySetInnerHTML={{ __html: text_heading }} /> :
                        <span slot="text-heading" dangerouslySetInnerHTML={{ __html: text_heading }} />) :
                    ''
            }
            {
                text_content ?
                    <span slot="text-content" dangerouslySetInnerHTML={{ __html: text_content }} /> :
                    ''
            }
            {
                text_sub_title ?
                    <span slot="text-sub-title" dangerouslySetInnerHTML={{ __html: text_sub_title }} /> :
                    ''
            }
            {
                image_url ? <img loading="lazy" slot="image" title={image_title} alt={image_alt} src={image_url.url} />
                    : ''
            }
            {
                video && !video_url && !image_url ? <iframe loading="lazy" slot='image' src={video.url} frameBorder="0" allow="fullscreen" />
                    : ''
            }
            {
                video_url && !video && !image_url ? <iframe loading="lazy" slot='image' title={video_url.title} src={video_url.href} frameBorder="0" allow="fullscreen" />
                    : ''
            }


        </OmTwoColumnImageLeft>
    );
};

export const pageQuery = graphql`
fragment TwoColumnImageLeft on Contentstack_2_col_image_left {
    id
    uid
    has_boundary
    use_heading_as_h1
    podcast_episode_id
    podcast_series_id
    text_heading
    text_sub_title
    text_content
    video_url {
    ...Link
    }
    video {
        ...File
    }
    image_url {
    	...File
    }
    image_title
    image_alt
    button_one_text
    button_one_url {
    	...Link
    }
    button_one_open_tab
    button_one_action_type
    button_two_text
    button_two_url {
    	...Link
    }
    button_two_action_type
    button_two_open_tab
    button_three_url {
    	...Link
    }
    button_three_image_url {
    	...File
    }
    button_three_open_tab
    button_four_url {
    	...Link
    }
    button_four_image_url {
    	...File
    }
    button_four_open_tab
    call_me_back_primary {
        ...CallMeBack
    }
    call_me_back_secondary {
        ...CallMeBack
    }
    roa_quote_tool_primary {
        ...RoaQuoteTool
    }
    roa_quote_tool_secondary {
        ...RoaQuoteTool
    }
}
`;

export default TwoColumnImageLeft
