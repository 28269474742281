import React, {useEffect} from 'react';
import { useDispatch } from 'react-redux';
import { OmArcSolutions } from '@om/component-library-react';
import { setSolutionsPageRenderTracking } from '@om/redux/actions/atRetirement/solutions';
import FourColumnIllustration from '../../../components/FourColumnIllustration';
import ProductSolutionsTable from './ProductSolutionsTable';
import RewardsSolutionsTable from './RewardsSolutionsTable';

const SolutionsPage = ({ fourColumnIllustration }) => {
    const dispatch = useDispatch();

    // dispatch action on render for tracking
    useEffect(() => {
        dispatch(setSolutionsPageRenderTracking());
    }, []);

    return (
        <OmArcSolutions>
            <ProductSolutionsTable slotName="recommended-solutions-table" />
            <RewardsSolutionsTable slotName="rewards-table" />
            <FourColumnIllustration
                slotName="four-column-illustration"
                fourColumnLayout={fourColumnIllustration}
            />
        </OmArcSolutions>
    );
};

export default SolutionsPage;